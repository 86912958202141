import styled from 'styled-components';
import Pattern from 'Static/background/pattern.png';

export const BrandLogo = styled.div`
  margin: 0;
`;

export const BrandedText = styled.span`
  color: #b4a589;
  ${({ theme }) => theme === 'dark'
    && `
    color: #ff0000;
    `};
`;

export const StyledButton = styled.button`
  cursor: pointer;
  border: none;
  position: relative;
  top: 0.3rem;
  background: none;
  text-align: left;
  img {
    margin: 0;
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  z-index: 1;
  margin-bottom: -3rem;
  box-shadow: 0 3px 9px 0 rgba(46, 50, 60, 0.09);
  font-size: 15px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    background-image: url(${Pattern});
    opacity: 1;
    background-position: center center;
    background-size: 32%;
    background-repeat: repeat;
    filter: brightness(0%);
  }
  padding: 0.5rem 1rem;

  > .container {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  ${({ isHomePage }) => isHomePage
    && `
		position: fixed;
		margin-bottom: unset;
	`} ${({ theme }) => theme === 'dark'
    && `
		background: #213141;
		border-bottom: unset;
		box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.2);
	`};
`;

export const List = styled.ul`
  list-style: none;
  align-self: center;
  a {
    color: #ff0000;
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled.div`
  transition: 0.5s;
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  margin-bottom: 0;

  &:hover {
    opacity: 0.7;
    transition: 0.5s;
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  display: flex;

  ${({ theme }) => theme === 'dark'
    && `
    color: #fff;
    `};

  ${({ copyright }) => copyright
    && `
    font-size: 12px;
	`};

  a {
    margin: 0;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: inherit;

    padding-top: 8px;
    padding-bottom: 8px;

    @media (max-width: 960px) {
      flex-direction: column;
    }

    @media (min-width: 960px) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const Links = styled.div`
  margin: 0;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const SocialLinks = styled.a`
  margin-right: 1rem;

  ${({ last }) => last
    && `
		margin-right: unset;
	`};
`;

export const Grow = styled.div`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
`;

export const Item = styled.img`
  margin: 0 0.2rem;

  ${({ img }) => img
    && `
		position: relative;
		top: .15rem;
	`};
`;

export const InputField = styled.div`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;
  box-sizing: border-box;
  transition: all 0.2s ease;
  text-align: left;
  border-width: 1px;
  border-color: #213141;
  border-style: solid;
  border-radius: 4px;
  padding: 0.6rem 1rem;
  -webkit-appearance: none;
  color: #828282;

  &:focus {
    border-color: #213141;
    transition: all 0.2s ease;
  }

  ${({ textarea }) => textarea
    && `
		resize: vertical;
		min-height: 8rem;
		margin: 0;
	`};
`;

export const Center = styled.div`
  text-align: center;
`;

export const Error = styled.div`
  color: red;
`;

export const Text = styled.h4`
  ${({ theme }) => theme === 'dark'
    && `
		color: #fff;
	`};
`;

export const HeroSection = styled.div`
  background-image: url(${props => props.bg});
  background-position: center center;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 44vh;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    // background-image: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0.7) 0,
    //   transparent 80%
    // );
  }
`;
