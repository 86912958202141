import styled from 'styled-components';

export const ArticleWrapper = styled.div`
  padding: 2rem 1rem;

  a {
    text-decoration: none;
    color: rgb(0, 119, 255);
  }

  i {
    color: #a7a7a7;
  }

  h1 {
    font-family: "Merriweather", serif;
    text-align: center;
  }
`;

export const Back = styled.div`
  margin-top: 2rem;
`;

export const Content = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const Comments = styled.div`
  margin-top: 2rem;
`;

export const ArticleDate = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  i {
    font-size: 0.9em;

    &:first-child {
      margin-right: 0.2rem;
    }
  }
`;

export const AuthorAvatar = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: 2rem;
  align-items: center;
  p {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    margin: 0 6px 0 0;
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
`;
