import React from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { ThemeContext } from 'Common';
import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDeck
} from 'reactstrap';

const CardProduct = ({ node }) => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <Card
        className="pointer hex-mask"
        inverse
        onClick={() => navigate(node.frontmatter.path)}
      >
        <Img
          fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
          className="square-mask"
        />
        <CardImgOverlay>
          <CardBody>
            <CardText className="mb-0">
              <small>Services for</small>
            </CardText>
            <CardTitle>{node.frontmatter.title}</CardTitle>
          </CardBody>
        </CardImgOverlay>
      </Card>
    )}
  </ThemeContext.Consumer>
);

export { CardProduct };

// import { Link, navigate } from 'gatsby';
// import Img from 'gatsby-image';
// import { ThemeContext } from 'Common';
// import {
//   Card,
//   CardImg,
//   CardImgOverlay,
//   CardText,
//   CardBody,
//   CardTitle,
//   CardSubtitle,
//   Button,
//   CardDeck
// } from 'reactstrap';

// const CardPost = ({ node }) => (
//   <ThemeContext.Consumer>
//     {({ theme }) => (
//       <Card
//         className="pointer"
//         inverse
//         onClick={() => navigate(node.frontmatter.path)}
//       >
//         {/* <CardImg top width="100%"> */}
//         <Img
//           fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
//           width="100%"
//           top
//         />
//         {/* </CardImg> */}
//         <CardImgOverlay>
//           <CardBody>
//             <CardText className="mb-0">
//               <small>Services for</small>
//             </CardText>
//             <CardTitle>{node.frontmatter.title}</CardTitle>
//             {/* <CardSubtitle >{node.excerpt}</CardSubtitle> */}

//             {/* <CardText  className="mb-0">
//               {node.frontmatter.date}
//             </CardText>
//             <CardText >
//               <small>{node.timeToRead} min</small>
//             </CardText> */}
//             {/* <Button

//               className="btn-link"
//               onClick={() => navigate(node.frontmatter.path)}
//             >
//               Read more
//             </Button> */}
//             {/* <Button color="link" className="text-white">
//               Read more
//             </Button> */}
//           </CardBody>
//         </CardImgOverlay>
//       </Card>
//     )}
//   </ThemeContext.Consumer>
// );

// export { CardPost };
