import config from 'Data';
import React from 'react';
import { Link } from 'gatsby';
import { GithubIcon, ThemeContext } from 'Common';
import gatsbyIcon from 'Static/footer/gatsby.svg';
import netlifyIcon from 'Static/footer/netlify.svg';
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';
import { Wrapper, Links, Flex } from 'Theme/styles';

const Copyrights = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <Container>
        <Flex>
          <Wrapper copyright>
            Copyright © {`${new Date().getFullYear()} `}
            {config.siteName}{' '}
          </Wrapper>
          <Wrapper>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </Wrapper>
        </Flex>
      </Container>
    )}
  </ThemeContext.Consumer>
);

export default Copyrights;
