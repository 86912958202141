import React from 'react';
import { Link } from 'gatsby';
import { FullContainer, ThemeContext } from 'Common';
import { Container, Row, Col } from 'reactstrap';
import { HeroSection } from 'Theme/styles';

const Hero = frontmatter => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <FullContainer>
        <HeroSection
          className="text-md-center"
          bg={frontmatter.thumbnail.childImageSharp.fluid.originalImg}
        >
          <Container theme={theme}>
            <Row>
              <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
                <h1>{frontmatter.title}</h1>
              </Col>
            </Row>
          </Container>
        </HeroSection>
      </FullContainer>
    )}
  </ThemeContext.Consumer>
);

export { Hero };
