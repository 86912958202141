import config from 'Data';
import React from 'react';
import {
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink
} from 'reactstrap';

import { Link } from 'gatsby';
import { ThemeContext } from 'Common';
import { Brand, StyledHeader } from 'Theme/styles';

export default class ReactNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <ThemeContext.Consumer>
        {({ theme, toggleTheme }) => (
          <Navbar id="navbar" fixed="top" expand="md" className="navbar-light">
            <StyledHeader>
              <Container>
                <Brand
                  as={Link}
                  to="/"
                  className="mr-auto navbar-logo"
                  color={theme}
                >
                  <span className="navbar-logo-wrapper">
                    <span className="navbar-logo-title">{config.title}</span>
                    <span className="navbar-logo-subtitle">
                      {config.subtitle}
                    </span>
                  </span>
                </Brand>
                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                <Collapse isOpen={!this.state.collapsed} navbar>
                  <Nav className="ml-auto my-3 my-md-0" navbar>
                    {/* <NavItem>
                      <NavLink href="/products">Products</NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <NavLink href="/blog">Blog</NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink href="/about">About us</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/contact">Contact</NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink href="/request-a-consultation">Request</NavLink>
                    </NavItem> */}
                    {/* <NavItem>
                      <StyledButton type="button" onClick={toggleTheme}>
                        <img src={theme === "dark" ? day : night} alt={theme} />
                      </StyledButton>
                    </NavItem> */}
                  </Nav>
                </Collapse>
              </Container>
            </StyledHeader>
          </Navbar>
        )}
      </ThemeContext.Consumer>
    );
  }
}
