import styled from 'styled-components';
import Pattern from 'Static/background/pattern.png';

const FullContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   min-height: 100%;
  //   opacity: 0.6;
  //   background-image: url(${Pattern});
  //   background-position: center center;
  //   background-size: 42%;
  //   background-repeat: repeat;
  // }

  ${({ details }) => details
    && `
    flex: 1
  `}

  ${({ nav }) => nav
    && `
    padding: .8rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}

  ${({ footer }) => footer
    && `
    padding: 2rem 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    @media (max-width: 680px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  `}
`;

export { FullContainer };
