import styled from 'styled-components';

export const StyledForm = styled.div`
  margin: 0 auto;
  background: #f6eed9;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 2rem;
  border-radius: 8px;

  @media (max-width: 960px) {
    padding: 2rem 1rem;
  }

  @media (max-width: 800px) {
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 1em;
  font-size: 1.2em;
  line-height: 1.55em;
  letter-spacing: 0.2px;

  @media (max-width: 800px) {
    font-size: 0.8em;
  }
`;

export const Title = styled.h3`
  margin: 0 0 3px;
  padding: 0;
  font-size: 1.5em;
  line-height: 1;
  font-weight: 700;

  @media (max-width: 800px) {
    font-size: 1em;
  }
`;

export const Message = styled.h4`
  margin: 1rem 0;
  font-weight: normal;

  @media (max-width: 800px) {
    font-size: 0.9em;
    margin: 1rem auto;
  }
`;

export const Error = styled.div`
  color: red;
  margin: 3px 0;

  ${({ server }) => server
    && `
        margin-top: 1rem;
    `};
`;

export const Fields = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 960px) {
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
