import config from 'Data';
import React from 'react';
import { Link } from 'gatsby';

import { ThemeContext } from 'Common';

import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDeck
} from 'reactstrap';

import { FullContainer } from 'Common';
import Copyrights from './Copyrights';
import Social from './Social';

const Footer = () => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <footer>
          <FullContainer>
            <Social />
            <Copyrights />
          </FullContainer>
        </footer>
      )}
    </ThemeContext.Consumer>
  );
};

export default Footer;
