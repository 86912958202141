import React from 'react';
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  GooglePlusIcon,
  LinkedinIcon,
  RedditIcon
} from 'react-share';
import config from 'Data';
import { Wrapper } from './styles';

const SocialShare = ({ title, path }) => {
  const iconSize = 32;
  return (
    <Wrapper>
      {/* <RedditShareButton url={`${config.siteUrl}${path}`} title={title}>
        <RedditIcon round size={iconSize} />
      </RedditShareButton> */}
      <TwitterShareButton url={`${config.siteUrl}${path}`} title={title}>
        <TwitterIcon round size={iconSize} />
      </TwitterShareButton>
      {/* <GooglePlusShareButton url={`${config.siteUrl}${path}`}>
        <GooglePlusIcon round size={iconSize} />
      </GooglePlusShareButton> */}
      <FacebookShareButton url={`${config.siteUrl}${path}`} quote={title}>
        <FacebookIcon round size={iconSize} />
      </FacebookShareButton>
      {/* <LinkedinShareButton
        url={`${config.siteUrl}${path}`}
        title={title}
        description={title}
      >
        <LinkedinIcon round size={iconSize} />
      </LinkedinShareButton> */}
      {/* <TelegramShareButton url={`${config.siteUrl}${path}`}>
        <TelegramIcon round size={iconSize} />
      </TelegramShareButton> */}
    </Wrapper>
  );
};

export { SocialShare };
