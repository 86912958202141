// TODO: add more social links for about page
module.exports = {
  title: 'Nutriheal',
  subtitle: 'Wellness Redefined',
  logo:
    'https://www.nutrihealcenter.com/static/favicon/apple-touch-icon-180x180.png',
  author: 'Alaa Abuirshaid',
  siteName: 'Nutrihealcenter.com',
  siteUrl: 'https://www.nutrihealcenter.com',
  favicon:
    'https://www.nutrihealcenter.com/static/favicon/apple-touch-icon-180x180.png',
  legalName: 'Nutriheal Wellness Center',
  description:
    'Weight Loss Made Easy, Your Holistic Clinical Approach to Health & Wellness',
  socialLinks: {
    facebook: 'https://www.facebook.com/nutriheal.center',
    twitter: 'https://www.twitter.com/',
    github: 'https://github.com/',
    linkedin: 'https://www.linkedin.com/',
    instagram: 'https://instagram.com/nutriheal.center/',
    unsplash: 'https://unsplash.com/',
    youtube: 'https://www.youtube.com/',
    google: 'https://plus.google.com/'
  },
  googleAnalyticsID: 'UA-xxxxxxxx-1',
  themeColor: '#b4a589',
  backgroundColor: '#b4a589',
  siteRss: '/rss.xml',
  social: {
    facebook: '',
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: ''
  },
  address: {
    city: '',
    region: '',
    country: 'Qatar',
    zipCode: 'P.O.Box 30158'
  },
  contact: {
    email: 'info@nutrihealcenter.com',
    phone1: '+974 444 20047',
    phone2: '+974 444 20048',
    address: 'Street 83, Building 42, Zone 71, Izghawa.'
  },
  foundingDate: '2019'
};
