import React from 'react';
// import Disqus from 'disqus-react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { SocialShare, PageTitle, ThemeContext } from 'Common';
import {
  Button,
  Collapse,
  Jumbotron,
  Container,
  Row,
  Col,
  H1,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardDeck
} from 'reactstrap';
import {
  ArticleWrapper,
  Back,
  Content,
  AuthorAvatar,
  Comments,
  ArticleDate
} from './styles';
// TODO:: add hero from blog image

import { Hero } from './Hero';

const Post = ({ html, frontmatter, timeToRead }) => (
  <ThemeContext.Consumer>
    {({ theme }) => {
      // const disqusShortName = 'r1q';
      // const disqusConfig = {
      //   url: `https://www.r1q.io${frontmatter.path}`,
      //   identifier: frontmatter.id,
      //   title: frontmatter.title
      // };
      return (
        <div>
          <Hero {...frontmatter} />
          <Container className="my-5 py-5">
            <Row>
              <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
                <AuthorAvatar>
                  <img
                    src={
                      frontmatter.author_avatar.childImageSharp.fluid
                        .originalImg
                    }
                  />
                  <p>
                    {frontmatter.author}{' '}
                    <small className="mr-auto">
                      {frontmatter.date} - {timeToRead} min read
                    </small>
                  </p>
                </AuthorAvatar>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
                <Content dangerouslySetInnerHTML={{ __html: html }} />
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="11" md="9" lg="8" className="mx-auto">
                <hr />
                <SocialShare {...frontmatter} />
                <Back>
                  <Link to={frontmatter.next}>Previous article</Link>
                </Back>
                {/* <Comments>
                  <Disqus.DiscussionEmbed
                    shortname={disqusShortName}
                    config={disqusConfig}
                  />
                </Comments> */}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }}
  </ThemeContext.Consumer>
);

export { Post };
