import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import { ThemeContext, Provider } from 'Common';
import Header from 'Theme/Header';
import Footer from 'Theme/Footer';
import './main.scss';
import Pattern from 'Static/background/pattern.png';

const Layout = ({ children }) => (
  <Provider>
    <ThemeContext.Consumer>
      {({ theme }) => (
        <React.Fragment>
          <Header />
          <main>
            <LayoutStyled>{children}</LayoutStyled>
          </main>
          <Footer />
        </React.Fragment>
      )}
    </ThemeContext.Consumer>
  </Provider>
);

const LayoutStyled = styled.div`
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    background-image: url(${Pattern});
    opacity: 0.5;
    background-position: center center;
    background-size: 32%;
    background-repeat: repeat;
    filter: brightness(0%);
  }
`;

export { Layout };
