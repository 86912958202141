import styled from "styled-components";

const CustomButton = styled.button`
  background: #b4a589;
  cursor: pointer;
  color: #fff;
  border-radius: 999px;
  margin: 0 auto;
  height: 50px;
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }
`;

export { CustomButton };
