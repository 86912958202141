import styled from "styled-components";

const Container = styled.div`
	max-width: 1200px;
	margin: 0 auto;
  position: relative;
  padding-left:15px;
  padding-right:15px;
	@media (min-width: 601px) {
	}

	@media (min-width: 993px) {
	}

	${({ details }) =>
    details &&
    `
		flex: 1
	`}

	${({ nav }) =>
    nav &&
    `
		padding: .8rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
	`}

	${({ footer }) =>
    footer &&
    `
		padding: 2rem 0;
		display: flex;
		justify-content: space-around;
		align-items: flex-start;

		@media (max-width: 680px) {
			flex-direction: column;
			justify-content: center;
			text-align: center;
		}
	`}
`;

export { Container };
