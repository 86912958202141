import config from 'Data';
import React from 'react';
import { Container } from 'reactstrap';

import {
  Facebook,
  Twitter,
  GithubIcon,
  Instagram,
  Unsplash,
  Linkedin,
  ThemeContext
} from 'Common';

import { Wrapper, SocialLinks, Flex, Grow } from 'Theme/styles';

const Social = () => {
  const social = [
    {
      id: 0,
      name: 'Facebook',
      link: config.socialLinks.facebook,
      icon: Facebook,
      last: false
    },
    // {
    //   id: 1,
    //   name: 'Twitter',
    //   link: config.socialLinks.twitter,
    //   icon: Twitter,
    //   last: false
    // },
    // {
    //   id: 1,
    //   name: 'Facebook',
    //   link: config.socialLinks.facebook,
    //   icon: Facebook,
    //   last: false
    // },
    {
      id: 2,
      name: 'Instagram',
      link: config.socialLinks.instagram,
      icon: Instagram,
      last: false
    }
    // {
    //   id: 3,
    //   name: 'Unsplash',
    //   link: config.socialLinks.unsplash,
    //   icon: Unsplash,
    //   last: false
    // },
    // {
    //   id: 4,
    //   name: 'Linkedin',
    //   link: config.socialLinks.linkedin,
    //   icon: Linkedin,
    //   last: true
    // }
  ];
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Container>
          <Flex>
            {/* <Links>
              Copyright © {`${new Date().getFullYear()} `}
              {config.siteName}{' '}
            </Links>
            <Link to="/privacy-policy">Privacy Policy</Link> */}
            <Wrapper>
              {social.map(({ id, name, link, icon, last }) => (
                <SocialLinks
                  key={id}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`follow us on ${name}`}
                  href={link}
                  last={last}
                >
                  <Grow as={icon} width="20" height="20" />
                </SocialLinks>
              ))}
            </Wrapper>
          </Flex>
        </Container>
      )}
    </ThemeContext.Consumer>
  );
};

export default Social;
