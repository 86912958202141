import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Link } from 'gatsby';
import { FullContainer, ThemeContext } from 'Common';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const Callout = () => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <FullContainer>
        <Container className="pb-5">
          <CalloutWrapper>
            <Container className="px-5">
              <Row>
                <Col md="6">
                  <h1>Your Holistic Clinical Approach to Health & Wellness</h1>
                  <p>
                    Get in touch to receive your <b>25%</b> pre launch discount.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link to="/contact" className="btn btn-primary mt-3">
                    Get it now
                  </Link>
                </Col>
              </Row>
            </Container>
          </CalloutWrapper>
        </Container>
      </FullContainer>
    )}
  </ThemeContext.Consumer>
);

const CalloutWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 480px;
  background-image: url(./background/thepearl-1.jpg);
  background-size: cover;
  background-position: center center;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  // filter: grayscale(70%);
  // transition: filter 0.6s;
  // &:hover {
  //   filter: grayscale(20%);
  // }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.6);
  }
`;

export { Callout };
