import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { SocialShare, PageTitle, ThemeContext } from 'Common';
import {
  Button,
  Card,
  CardBody,
  CardDeck,
  CardImg,
  CardImgOverlay,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  H1,
  Jumbotron,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import {
  ArticleWrapper,
  Back,
  Next,
  Content,
  Related,
  AuthorAvatar,
  Comments,
  ArticleDate
} from './styles';
import { Hero } from './Hero';

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    const { html, frontmatter, timeToRead } = this.props;

    return (
      <ThemeContext.Consumer>
        {({ theme }) => {
          return (
            <div>
              <Hero {...frontmatter} />
              <Container className="my-5 py-5">
                <Row>
                  <Col xs="12" sm="12" md="10" lg="10" className="mx-auto">
                    <Content dangerouslySetInnerHTML={{ __html: html }} />
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="10"
                    lg="10"
                    className="mx-auto text-center"
                  >
                    {/* <Button
                      color="primary"
                      onClick={this.toggle}
                      className="px-4"
                    >
                      Request a Consultation
                    </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="10" lg="10" className="mx-auto">
                    <h6>
                      <b>Share on</b>
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="10" lg="10" className="mx-auto">
                    <SocialShare {...frontmatter} />
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="12" md="10" lg="10" className="mx-auto">
                    <Related>
                      <Back>
                        <Link
                          to={frontmatter.previous}
                          className="btn btn-primary px-5"
                        >
                          Back
                        </Link>
                      </Back>
                      <Next>
                        <Link
                          to={frontmatter.next}
                          className="btn btn-primary px-5"
                        >
                          Next
                        </Link>
                      </Next>
                    </Related>
                  </Col>
                </Row>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
                  <ModalBody>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={this.toggle}>
                      Do Something
                    </Button>{' '}
                    <Button color="primary" onClick={this.toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Container>
            </div>
          );
        }}
      </ThemeContext.Consumer>
    );
  }
}

export { Service };
